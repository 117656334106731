import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import EquipmentGroup from '../../entities/EquipmentGroup'
import urlifyGroupName from '../../helpers/urlifyGroupName'
import OverviewEquipmentTable from './OverviewEquipmentTable'
import style from './overviewEquipmentGroups.css'

export type Props = {
    selectedEquipmentGroups: EquipmentGroup[]
    deselectEquipment: Function
    openInfoModal: Function
}

const OverviewEquipmentGroups: FunctionComponent<Props> = (props) => {
    const {
        selectedEquipmentGroups, deselectEquipment, openInfoModal,
    } = props
    const { t } = useTranslation()

    const standardGroupId = 'standards'

    const isStandardGroupPresent = selectedEquipmentGroups.filter(g => g.id === standardGroupId)?.length

    const selectedEquipmentGroupElements = selectedEquipmentGroups.map((equipmentGroup) => {
        if (!equipmentGroup) {
            return null
        }

        const url = `${urlifyGroupName(equipmentGroup.id)}`
        const { equipmentFamilies } = equipmentGroup

        if (!equipmentFamilies) {
            return null
        }

        const allEquipmentsInGroup = equipmentFamilies
            .flatMap((equipmentFamily) => equipmentFamily.equipments)

        let content = undefined

        if (allEquipmentsInGroup.length !== 0) {
            content = equipmentFamilies.map(equipmentFamily => {
                const equipments = isStandardGroupPresent && equipmentGroup.id !== standardGroupId
                    ? equipmentFamily.equipments.filter(eq => !eq.isStandard)
                    : equipmentFamily.equipments
                return (equipments.length === 0) ?
                    undefined
                    : (
                        <div key={equipmentFamily.id}>
                            <h3>{equipmentFamily.name}</h3>
                            <OverviewEquipmentTable
                                equipments={equipments}
                                url={url}
                                deselectEquipment={deselectEquipment}
                                openInfoModal={openInfoModal}
                            />
                        </div>
                    )
            })
        } else {
            content = (
                <p>
                    <Link to={url} className={style.fallbackLink}>
                        {t('overviewSelection.fallbackLink')}
                    </Link>
                </p>
            )
        }

        return (
            <section key={equipmentGroup.id}>
                <h2 className={style.title}>
                    {t(`navigation.${equipmentGroup.id}`)}
                </h2>
                {content}
            </section>
        )
    })

    return (
        <>
            {selectedEquipmentGroupElements}
        </>
    )
}

export default OverviewEquipmentGroups
