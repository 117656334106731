import { createSelector } from 'reselect'
import equipmentGroupsSelector from './equipmentGroupsSelector'
import EquipmentGroup from '../../../entities/EquipmentGroup'
import filterEquipmentsOfGroups from './helpers/filterEquipmentsOfGroups'

const overviewEquipmentGroupsSelector = createSelector(
    equipmentGroupsSelector,
    (equipmentGroups): EquipmentGroup[] => (
        filterEquipmentsOfGroups(equipmentGroups, (equipment) => equipment.isStandard || equipment.selected)
    ),
)

export default overviewEquipmentGroupsSelector
